<template>
    <svg fill="none" height="54" viewBox="0 0 250 54" width="250" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M122.983 46.8906V50.9431H121.652V47.1598C121.652 46.1679 121.206 45.6406 120.227 45.6406C119.097 45.6406 118.483 46.3744 118.483 47.6134V50.9394H117.152V41.9495H118.483V45.5705C118.941 44.822 119.647 44.3795 120.651 44.3795C122.098 44.3795 122.983 45.3235 122.983 46.8906Z"
            fill="white"/>
        <path
            d="M131.318 47.7314C131.318 49.6562 129.845 51.0832 127.964 51.0832C126.082 51.0832 124.609 49.6488 124.609 47.7314C124.609 45.8139 126.115 44.3795 127.964 44.3795C129.812 44.3795 131.318 45.7918 131.318 47.7314ZM125.948 47.7314C125.948 48.9703 126.829 49.8221 127.96 49.8221C129.09 49.8221 129.972 48.9703 129.972 47.7314C129.972 46.4924 129.076 45.6406 127.96 45.6406C126.844 45.6406 125.948 46.4924 125.948 47.7314Z"
            fill="white"/>
        <path
            d="M132.683 48.8745H134.037C134.238 49.5861 134.766 49.9143 135.886 49.9143C136.745 49.9143 137.277 49.6562 137.277 49.1805C137.277 48.6422 136.663 48.561 135.581 48.3029C133.766 47.8604 133.003 47.4991 133.003 46.4223C133.003 45.1281 134.111 44.3795 135.737 44.3795C137.362 44.3795 138.329 45.1612 138.563 46.3302H137.221C137.031 45.8065 136.536 45.5484 135.714 45.5484C134.892 45.5484 134.349 45.8397 134.349 46.2822C134.349 46.6915 134.751 46.7948 135.882 47.0639C137.637 47.4733 138.649 47.7756 138.649 49.0257C138.649 50.4048 137.399 51.0796 135.871 51.0796C134.164 51.0796 132.94 50.2609 132.691 48.8745H132.683Z"
            fill="white"/>
        <path
            d="M145.154 50.6149C144.801 50.8251 144.046 51.0832 143.306 51.0832C141.974 51.0832 140.94 50.2536 140.94 48.4799V45.7586H139.739V44.5196H140.94V43.0594L142.272 42.7681V44.5196H144.935V45.7586H142.272V48.4099C142.272 49.3428 142.707 49.859 143.544 49.859C143.956 49.859 144.414 49.7189 144.92 49.5456L145.154 50.6186V50.6149Z"
            fill="white"/>
        <path
            d="M148.248 42.4325C148.248 42.9119 147.884 43.2622 147.378 43.2622C146.872 43.2622 146.519 42.9119 146.519 42.4325C146.519 41.9532 146.895 41.6287 147.378 41.6287C147.862 41.6287 148.248 41.9679 148.248 42.4325ZM146.716 44.5233H148.048V50.9431H146.716V44.5233Z"
            fill="white"/>
        <path
            d="M155.772 46.8906V50.9431H154.441V47.1598C154.441 46.1679 154.006 45.6406 153.028 45.6406C151.886 45.6406 151.272 46.3744 151.272 47.6134V50.9394H149.941V44.5196H151.272V45.5816C151.73 44.833 152.425 44.3795 153.44 44.3795C154.902 44.3795 155.772 45.3235 155.772 46.8906Z"
            fill="white"/>
        <path
            d="M163.988 44.5196V50.8583C163.988 52.7721 162.776 54 160.857 54C159.313 54 157.911 53.2072 157.628 51.6069H159.005C159.228 52.4365 159.946 52.82 160.82 52.82C162.01 52.82 162.657 52.1563 162.657 50.9062V49.8074C162.173 50.5191 161.396 51.0095 160.396 51.0095C158.666 51.0095 157.405 49.6193 157.405 47.6945C157.405 45.7696 158.688 44.3795 160.396 44.3795C161.396 44.3795 162.185 44.881 162.657 45.6185V44.5196H163.988ZM162.668 47.6945C162.668 46.5034 161.809 45.6406 160.678 45.6406C159.548 45.6406 158.748 46.5034 158.748 47.6945C158.748 48.8855 159.559 49.7484 160.678 49.7484C161.798 49.7484 162.668 48.8966 162.668 47.6945Z"
            fill="white"/>
        <path
            d="M171.817 44.4865V44.5233H174.209V45.7623H171.817V50.9468H170.486V45.7623H169.285V44.5233H170.486V44.4311C170.486 42.6796 171.617 41.8167 173.008 41.8167C173.502 41.8167 174.068 41.9237 174.469 42.097L174.235 43.1811L174.045 43.1221C173.74 43.0299 173.432 42.982 173.164 42.982C172.234 42.982 171.821 43.5056 171.821 44.4865H171.817Z"
            fill="white"/>
        <path
            d="M182.027 47.7314C182.027 49.6562 180.554 51.0832 178.672 51.0832C176.79 51.0832 175.317 49.6488 175.317 47.7314C175.317 45.8139 176.823 44.3795 178.672 44.3795C180.52 44.3795 182.027 45.7918 182.027 47.7314ZM176.656 47.7314C176.656 48.9703 177.537 49.8221 178.668 49.8221C179.799 49.8221 180.68 48.9703 180.68 47.7314C180.68 46.4924 179.784 45.6406 178.668 45.6406C177.552 45.6406 176.656 46.4924 176.656 47.7314Z"
            fill="white"/>
        <path
            d="M187.583 44.4164V45.7586C187.464 45.7475 187.36 45.7365 187.182 45.7365C185.969 45.7365 185.039 46.415 185.039 48.0005V50.9431H183.708V44.5233H185.039V45.7955C185.426 44.8957 186.17 44.3832 187.193 44.3832C187.356 44.3832 187.487 44.3943 187.58 44.4164H187.583Z"
            fill="white"/>
        <path
            d="M199.251 50.9394H197.919V49.8664C197.447 50.6039 196.67 51.0796 195.658 51.0796C193.928 51.0796 192.668 49.6894 192.668 47.7277C192.668 45.766 193.951 44.3758 195.647 44.3758C196.67 44.3758 197.447 44.8773 197.919 45.6148V44.5159H199.251V50.9394ZM197.93 47.7314C197.93 46.5071 197.071 45.6406 195.941 45.6406C194.81 45.6406 194.01 46.5035 194.01 47.7314C194.01 48.9593 194.821 49.8221 195.941 49.8221C197.06 49.8221 197.93 48.9703 197.93 47.7314Z"
            fill="white"/>
        <path
            d="M207.526 44.5196V50.8583C207.526 52.7721 206.314 54 204.394 54C202.851 54 201.449 53.2072 201.166 51.6069H202.542C202.765 52.4365 203.483 52.82 204.357 52.82C205.547 52.82 206.195 52.1563 206.195 50.9062V49.8074C205.711 50.5191 204.934 51.0095 203.933 51.0095C202.204 51.0095 200.943 49.6193 200.943 47.6945C200.943 45.7696 202.226 44.3795 203.933 44.3795C204.934 44.3795 205.722 44.881 206.195 45.6185V44.5196H207.526ZM206.206 47.6945C206.206 46.5034 205.347 45.6406 204.216 45.6406C203.085 45.6406 202.286 46.5034 202.286 47.6945C202.286 48.8855 203.096 49.7484 204.216 49.7484C205.335 49.7484 206.206 48.8966 206.206 47.6945Z"
            fill="white"/>
        <path
            d="M215.839 48.0927H210.576C210.68 49.2727 211.517 49.9254 212.603 49.9254C213.38 49.9254 214.027 49.5972 214.332 48.9667H215.708C215.203 50.4011 213.964 51.0796 212.588 51.0796C210.621 51.0796 209.218 49.6783 209.218 47.7388C209.218 45.7992 210.609 44.3758 212.562 44.3758C214.645 44.3758 215.965 45.9761 215.835 48.089L215.839 48.0927ZM210.647 47.0197H214.414C214.213 46.1421 213.544 45.5263 212.566 45.5263C211.588 45.5263 210.87 46.1568 210.647 47.0197Z"
            fill="white"/>
        <path
            d="M223.314 46.8906V50.9431H221.983V47.1598C221.983 46.1679 221.548 45.6406 220.569 45.6406C219.428 45.6406 218.814 46.3744 218.814 47.6134V50.9394H217.482V44.5196H218.814V45.5816C219.271 44.833 219.967 44.3795 220.982 44.3795C222.444 44.3795 223.314 45.3235 223.314 46.8906Z"
            fill="white"/>
        <path
            d="M224.951 47.7314C224.951 45.8176 226.386 44.3795 228.32 44.3795C229.853 44.3795 231.207 45.3014 231.5 46.8538H230.124C229.875 46.0462 229.157 45.6037 228.324 45.6037C227.123 45.6037 226.297 46.4924 226.297 47.7277C226.297 48.963 227.145 49.8516 228.309 49.8516C229.157 49.8516 229.875 49.3834 230.124 48.5906H231.5C231.195 50.1798 229.827 51.0759 228.309 51.0759C226.39 51.0759 224.954 49.6415 224.954 47.724L224.951 47.7314Z"
            fill="white"/>
        <path
            d="M234.606 42.4325C234.606 42.9119 234.242 43.2622 233.736 43.2622C233.23 43.2622 232.877 42.9119 232.877 42.4325C232.877 41.9532 233.252 41.6287 233.736 41.6287C234.219 41.6287 234.606 41.9679 234.606 42.4325ZM233.074 44.5233H234.405V50.9431H233.074V44.5233Z"
            fill="white"/>
        <path
            d="M242.707 48.0927H237.444C237.548 49.2727 238.385 49.9254 239.471 49.9254C240.248 49.9254 240.895 49.5972 241.2 48.9667H242.576C242.071 50.4011 240.832 51.0796 239.456 51.0796C237.488 51.0796 236.086 49.6783 236.086 47.7388C236.086 45.7992 237.477 44.3758 239.43 44.3758C241.513 44.3758 242.833 45.9761 242.703 48.089L242.707 48.0927ZM237.514 47.0197H241.282C241.081 46.1421 240.412 45.5263 239.434 45.5263C238.455 45.5263 237.738 46.1568 237.514 47.0197Z"
            fill="white"/>
        <path
            d="M244.034 48.8745H245.388C245.589 49.5861 246.117 49.9143 247.237 49.9143C248.096 49.9143 248.628 49.6562 248.628 49.1805C248.628 48.6422 248.014 48.561 246.932 48.3029C245.117 47.8604 244.354 47.4991 244.354 46.4223C244.354 45.1281 245.463 44.3795 247.088 44.3795C248.713 44.3795 249.68 45.1612 249.915 46.3302H248.572C248.382 45.8065 247.887 45.5484 247.066 45.5484C246.244 45.5484 245.701 45.8397 245.701 46.2822C245.701 46.6915 246.102 46.7948 247.233 47.0639C248.988 47.4733 250 47.7756 250 49.0257C250 50.4048 248.75 51.0796 247.222 51.0796C245.515 51.0796 244.291 50.2609 244.042 48.8745H244.034Z"
            fill="white"/>
        <path
            d="M64.7918 31.8667C59.663 31.8667 56.1631 28.124 56.1631 22.9579C56.1631 17.7918 59.663 14.0454 64.7918 14.0454C68.2136 14.0454 70.8766 15.7785 72.1895 18.6178H78.2742C76.6414 12.7437 71.4754 8.68018 64.829 8.68018C56.5165 8.68018 50.4318 14.8308 50.4318 22.9542C50.4318 31.0776 56.5165 37.2246 64.829 37.2246C71.4717 37.2246 76.6414 33.1647 78.2742 27.287H72.1895C70.8766 30.1263 68.2136 31.8594 64.7918 31.8594"
            fill="white"/>
        <path
            d="M96.3275 8.68387C88.1339 8.68387 81.8521 14.8345 81.8521 22.9579C81.8521 31.0813 88.1377 37.2283 96.3275 37.2283C104.517 37.2283 110.844 31.0776 110.844 22.9579C110.844 14.8382 104.521 8.68387 96.3275 8.68387ZM96.3275 31.8667C91.3176 31.8667 87.5798 28.124 87.5798 22.9579C87.5798 17.7918 91.3176 14.0454 96.3275 14.0454C101.337 14.0454 105.075 17.7881 105.075 22.9579C105.075 28.1277 101.337 31.8667 96.3275 31.8667Z"
            fill="white"/>
        <path
            d="M131.497 26.3872C135.395 25.4027 137.741 22.6408 137.741 18.1458C137.741 12.6663 134.201 9.15587 128.235 9.15587H115.061C115.061 12.1169 117.483 14.5174 120.469 14.5174H127.599C130.541 14.5174 131.973 15.9371 131.973 18.2233C131.973 20.5095 130.582 21.8886 127.599 21.8886H116.263V36.7526H121.949V26.815H125.092L132.293 36.7526H139.292L131.497 26.3836V26.3872Z"
            fill="white"/>
        <path
            d="M187.133 21.8923C189.402 20.7492 190.596 19.0935 190.596 16.3316C190.596 11.6006 187.215 9.15587 182.086 9.15587H169.876V36.76H182.164C187.892 36.76 191.552 33.8395 191.552 28.7951C191.552 25.5612 190.12 22.8805 187.137 21.8959M175.563 14.5211H181.331C183.719 14.5211 184.872 15.2696 184.872 17.283C184.872 19.1377 183.838 20.0854 181.491 20.0854H175.563V14.5211ZM182.045 31.3984H175.563V25.2478H182.008C184.95 25.2478 185.783 26.3098 185.783 28.3231C185.783 30.1742 184.95 31.3984 182.045 31.3984Z"
            fill="white"/>
        <path
            d="M209.977 8.68387C201.783 8.68387 195.502 14.8345 195.502 22.9579C195.502 31.0813 201.787 37.2283 209.977 37.2283C218.167 37.2283 224.493 31.0776 224.493 22.9579C224.493 14.8382 218.167 8.68387 209.977 8.68387ZM209.977 31.8667C204.967 31.8667 201.226 28.124 201.226 22.9579C201.226 17.7918 204.963 14.0454 209.977 14.0454C214.991 14.0454 218.725 17.7881 218.725 22.9579C218.725 28.1277 214.987 31.8667 209.977 31.8667Z"
            fill="white"/>
        <path
            d="M241.048 22.8805L249.755 9.15587H242.993L237.585 18.0278L232.215 9.15587H225.457L234.007 22.4454L225.018 36.76H231.779L237.425 27.2538L243.194 36.76H249.993L241.048 22.8805Z"
            fill="white"/>
        <path d="M148.475 14.521C145.489 14.521 143.067 12.1205 143.067 9.15952H163.062V14.521H148.475Z" fill="white"/>
        <path d="M148.475 25.6387C145.489 25.6387 143.067 23.2381 143.067 20.2771H163.062V25.6387H148.475Z"
              fill="white"/>
        <path d="M148.475 36.7563C145.489 36.7563 143.067 34.3558 143.067 31.3947H163.062V36.7563H148.475Z"
              fill="white"/>
        <path clip-rule="evenodd" d="M19.0654 0.388803C19.9879 -0.129601 21.1171 -0.129601 22.0396 0.388803L30.8288 5.32819L39.5376 10.4055C40.4517 10.9384 41.0163 11.908 41.0247 12.9593L41.105 22.976L41.0247 32.9927C41.0163 34.044 40.4517 35.0136 39.5376 35.5465L30.8288 40.6238L22.0396 45.5632C21.1171 46.0816 19.9879 46.0816 19.0654 45.5632L10.2763 40.6238L1.56739 35.5465C0.653364 35.0136 0.088735 34.044 0.0803066 32.9927L0 22.976L0.0803066 12.9593C0.088735 11.908 0.653364 10.9384 1.56739 10.4055L10.2763 5.32819L19.0654 0.388803ZM7.44806 13.1556L21.7598 21.3483C22.481 21.7611 23.0657 22.7652 23.0657 23.5909V28.58C23.0657 29.3482 22.2355 29.8295 21.5688 29.4479L9.86262 22.7467C9.38181 22.4715 8.99204 22.6946 8.99204 23.2451V26.2805C8.99204 26.831 9.38181 27.5004 9.86261 27.7756L22.0593 34.7575C22.6817 35.1138 23.0657 35.7761 23.0657 36.4933V38.3949C23.0657 41.1473 21.1168 42.2629 18.7128 40.8867L5.27161 33.1924C4.5504 32.7795 3.96574 31.7755 3.96574 30.9498V15.149C3.96574 12.9471 5.52483 12.0546 7.44806 13.1556ZM22.0395 4.87405C21.1171 4.35565 19.9878 4.35565 19.0654 4.87405L12.244 8.70757L9.86808 10.1804L17.8116 14.4259L24.7398 18.4051C26.143 19.211 27.0098 20.6775 27.0228 22.2675L27.0866 30.1178L27.0118 38.3502L28.8609 37.2444L35.6199 33.3039C36.534 32.771 37.0986 31.8014 37.107 30.7501L37.1694 22.976L37.107 15.2019C37.0986 14.1507 36.534 13.181 35.6199 12.6481L28.8609 8.70757L22.0395 4.87405Z"
              fill="url(#paint0_linear_5_111)"
              fill-rule="evenodd"/>
        <defs>
            <linearGradient id="paint0_linear_5_111" gradientUnits="userSpaceOnUse" x1="2.67838" x2="37.4421" y1="22.9759"
                            y2="22.8256">
                <stop stop-color="#308F83"/>
                <stop offset="1" stop-color="#52E15F"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'CoreboxLogoLarge',
};
</script>

<style scoped>

</style>
